import React from "react";
import DashboardContainer from "../components/DashboardContainer";

const DrawingDashboard = () => {
  return (
    <>
      <DashboardContainer />
    </>
  );
};

export default DrawingDashboard;
