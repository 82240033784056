const resetAllData = () => {
  return {
    type: "RESET_ALL_DATA",
  };
};

const exportDefault = {
  resetAllData,
};

export default exportDefault;
