import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
// import { FaAngleDown } from "react-icons/fa";
import { Grid, Typography } from "@mui/material";
import "./SideBar2.css";
import FaAngleDown from "@mui/icons-material/KeyboardArrowDownSharp";
import { NavLink } from "react-router-dom";

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    x: "-100%",
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
  show: (i) => ({
    x: 0,
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
};

const Sidebar2Menus = ({ color, route, showAnimation, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div
        className="menu"
        style={
          isOpen
            ? {
                justifyContent: "space-between",
                padding: "0.4em 0.4em 0.8em",
                cursor: "pointer",
              }
            : {
                padding: "0.4em 0.4em 0.8em",
                cursor: "pointer",
                justifyContent: "center",
              }
        }
        onClick={toggleMenu}
      >
        <div className="menu_item">
          <Grid
            className="icon"
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {route.icon}

            {!isOpen && (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "8.5px",
                    gap: "7px",
                  }}
                >
                  {" "}
                  {route.name}
                </Typography>
              </Grid>
            )}
          </Grid>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="link_text"
              >
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <motion.div
            animate={
              isMenuOpen
                ? {
                    rotate: -90,
                  }
                : { rotate: 0 }
            }
          >
            <FaAngleDown />
          </motion.div>
        )}
      </div>{" "}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="menu_container"
          >
            {route.subRoutes.map((subRoute, i) => (
              <motion.div variants={menuItemAnimation} key={i} custom={i}>
                <NavLink
                  to={`/crm/crm${subRoute.path}`}
                  style={{ paddingleft: "0.6em", paddingTop: "0.5em" }}
                  className="link"
                >
                  <div
                    className="icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/crm/crm${subRoute.path}`)}
                  >
                    {subRoute.icon}
                  </div>
                  <motion.div className="link_text">{subRoute.name}</motion.div>
                </NavLink>
              </motion.div>
            ))}
          </motion.div>
        )}{" "}
      </AnimatePresence>
    </>
  );
};

export default Sidebar2Menus;
