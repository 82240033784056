import React from "react";
import CampaignContainer from "../components/CampaignContainer";

const CampaignHome = () => {
  return (
    <>
      <CampaignContainer />
    </>
  );
};

export default CampaignHome;
