import CampaignIcon from "@mui/icons-material/Campaign";

export const routes = [
  {
    path: "",
    to: "/campaign",
    name: "Campaign",
    icon: <CampaignIcon />,
  },
];
