import React from "react";

const DrawingTransmittal = () => {
  console.log("DrawingTransmittal");
  return (
    <div style={{ fontSize: "50rem", marginTop: "100px" }}>
      DrawingTransmittal
    </div>
  );
};

export default DrawingTransmittal;
