import React from "react";
import CreateUpdateDrawing from "../components/CreateUpdateDrawing";

const DrawingDetails = () => {
  return (
    <div>
      <CreateUpdateDrawing />
    </div>
  );
};

export default DrawingDetails;
